$(document).ready(function () {
	$('.js-city').on('click', function (e) {
		e.preventDefault();

		var city = $(this).data('city');

		$('.js-home-header-animation').animate(
			{
				top: '-250%',
			},
			500
		);
		$('.js-city-item').hide();
		$('.js-city-item[data-city="' + city + '"]').show();
	});

	$('.js-menu-logo').on('click', function (e) {
		e.preventDefault();

		$('.js-home-header-animation').animate(
			{
				top: '0',
			},
			500,
			function () {
				$('.js-city-item').hide();
			}
		);
	});
});
