function hubspotTooltip() {
	var $email = $('.hs-email .input'),
		$emailInput = $('.hs-email .input input'),
		$emailError = $('.hs-email .hs-error-msg');

	$email.append(
		'<div class="custom-error" data-toggle="tooltip" title="We require a corporate email address, if your company does not have one please email marketing@chargebacks911.com">?</div>'
	);

	$('[data-toggle="tooltip"]').tooltip();

	setInterval(function () {
		var text = '';

		if ($('.hs-email .hs-error-msg').length) {
			text = $('.hs-email .hs-error-msg').html();
			if (text.indexOf('Please enter a different email address.') >= 0) {
				$('.custom-error').addClass('invalid');
			} else {
				$('.custom-error').removeClass('invalid');
			}
		}
	}, 100);
}

$(document).ready(function () {
	// hbspt.forms.create({
	// 	portalId: '2611577',
	// 	formId: '3249eb17-3436-4c45-84f5-b0674857362a',
	// 	target: '.js-hubspot-one',
	// 	css: '',
	// 	onFormReady: function ($form, ctx) {
	// 		hubspotTooltip();
	// 	},
	// 	onFormSubmit: function ($form) {},
	// // });

	// hbspt.forms.create({
	// 	portalId: '2611577',
	// 	formId: '1d955939-6230-47f3-80f1-b5074968f43e',
	// 	target: '.js-hubspot-two',
	// 	css: '',
	// 	onFormReady: function ($form, ctx) {
	// 		hubspotTooltip();
	// 	},
	// 	onFormSubmit: function ($form) {},
	// });

	// hbspt.forms.create({
	// 	portalId: '2611577',
	// 	formId: 'cfd5393c-6fbd-40d9-aa0b-fd68f3ef2c6a',
	// 	target: '.js-hubspot-three',
	// 	css: '',
	// 	onFormReady: function ($form, ctx) {
	// 		hubspotTooltip();
	// 	},
	// 	onFormSubmit: function ($form) {},
	// });>

	// new eventbrite stuff - MARYLAND (ONE)
	hbspt.forms.create({

		// little form placeholder
		region: "na1",
		portalId: "2611577",
		formId: "b71ac013-28c9-40c8-b2b2-f3b5c3da2c1f",
		target: ".js-hubspot-one"

	// 	portalId: '2611577',
	// 	formId: 'b682325e-24a9-4a7a-9530-ef16bdd6952f', 
	// 	target: '.js-hubspot-one',
	// 	css: '',
	// 	onFormReady: function ($form, ctx) {
	// 		hubspotTooltip();
	// 	},
	// 	onFormSubmit: function ($form) {
	// 		$('#eventbrite-widget-modal-trigger-72950495897').click(); // need to change these values to maryland eventbrite modal
	// 	},
	});

	// new eventbrite stuff - LAS VEGAS (TWO)
	hbspt.forms.create({

		// little form placeholder
		region: "na1",
		portalId: "2611577",
		formId: "b71ac013-28c9-40c8-b2b2-f3b5c3da2c1f",
		target: ".js-hubspot-two"
		// portalId: '2611577',
		// formId: 'f2a00023-3f2b-421f-81fa-4dd3b138f6e7', 
		// target: '.js-hubspot-two',
		// css: '',
		// onFormReady: function ($form, ctx) {
		// 	hubspotTooltip();
		// },
		// onFormSubmit: function ($form) {
		// 	$('#eventbrite-widget-modal-trigger-72950495897').click(); // need to change these values to las vegas eventbrite modal
		// },
	});

	// new eventbrite stuff - BARCELONA (Three)
	hbspt.forms.create({

		// little form placeholder
		region: "na1",
		portalId: "2611577",
		formId: "b71ac013-28c9-40c8-b2b2-f3b5c3da2c1f",
		target: ".js-hubspot-three"
		// portalId: '2611577',
		// formId: '42fdb48a-2f54-4bcd-a1e4-61183989a5c8',
		// target: '.js-hubspot-three',
		// css: '',
		// onFormReady: function ($form, ctx) {
		// 	hubspotTooltip();
		// },
		// onFormSubmit: function ($form) {
		// 	$('#eventbrite-widget-modal-trigger-72950495897').click(); // need to change these values to barcelona eventbrite modal
		// },
	});
});
